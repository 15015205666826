/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Lazy load Images across the app
        jQuery('.lazy').Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
            visibleOnly: true,
            afterLoad: function(element) {
                element.addClass('loaded');
            }
        });


        $('.search-field').attr('autocomplete', 'off');

        // Search Support
        $('#search-kassatex, .search-input').on('click touchstart', function(e){
            e.preventDefault();
            var searchForm = $('.search-header');

            if( $(this).hasClass('open') ) {
                $(this).removeClass('open');
                searchForm.removeClass('visible-search');
            } else {
                $(this).addClass('open');
                searchForm.addClass('visible-search');
            }
        });

        // Add Active Support to Link because of the cached Menu.
        var url = window.location.href;
        $('.menu a[href="' + url + '"]').addClass('current-page');

        // Sticky Header Navigation
        var containerHeader = document.getElementById("container");
        var sticky = containerHeader.offsetTop;

        var imgLogo = document.getElementById('site-title-img');

        if(imgLogo !== null) {
          var imglogoLarge = imgLogo.getAttribute('option-lg');
          var imglogoSmall = imgLogo.getAttribute('option-sm');

          function scrollFixHeader() {

            var header = jQuery('.site-header')[0];

            if (window.pageYOffset >= sticky) {
              header.classList.add("sticky");
              imgLogo.setAttribute('src', imglogoSmall);
              containerHeader.style.paddingTop =  sticky + "px";
            } else {
              setTimeout(function(){
                header.classList.remove("sticky");
                imgLogo.setAttribute('src', imglogoLarge);
                containerHeader.style.paddingTop =  "0";
              }, 100);
            }
          }

          window.onscroll = function() {scrollFixHeader();};
        }


        //Pagination
        $('.pagination-alpha, .pagination-omega').on('click touchstart', function(event){
          event.preventDefault();
          var linkPagination = $(this).find("a").attr("href");
          window.location.href = linkPagination;
        });

        var alpha = jQuery.trim( jQuery('.pagination-alpha').html() ).length ;
        var omega = jQuery.trim( jQuery('.pagination-omega').html() ).length ;

        if(alpha > 0 && omega > 0) {
          $('.single-pagination').addClass('invisible');
        }

      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
